import { gql } from '@apollo/client';

export interface IGQLTeachersResponse<T> {
  teachers: {
    edges: T[];
    pageInfo: {
      pages: number;
      total: number;
    };
  };
}

export interface IGQLTeacherResponse<T> {
  teacher: T;
}

export const TEACHER_SEARCH_GQL = gql`
  query Teachers($filter: TeachersFilter, $pagination: Pagination) {
    teachers(filter: $filter, pagination: $pagination) {
      pageInfo {
        pages
        total
      }
      edges {
        id
        firstName
        lastName
        center
        email
        personalInterests
        instructionLanguages
        emailSubscribed
        skills
        path
      }
    }
  }
`;

export const TEACHER_DEPARTMENT_SCHOOL_GQL = gql`
  query Teachers($filter: TeachersFilter, $pagination: Pagination) {
    teachers(filter: $filter, pagination: $pagination) {
      edges {
        id
        firstName
        lastName
        department {
          id
          name
          school {
            id
            name
          }
        }
      }
    }
  }
`;

export const TEACHER_SCHOOL_GQL = gql`
  query Teacher($id: ID!) {
    teacher(id: $id) {
      id
      firstName
      lastName
      department {
        id
        name
        school {
          id
          name
        }
      }
    }
  }
`;
