import LocalForage from 'utils/localForage';

import { FeatureFlagEnum } from './enums';

const addFF = async (ffName: FeatureFlagEnum) => {
  const existingPersistedFeatureFlags = (await LocalForage.getItem('persisted_feature_flags')) || [];

  if (!existingPersistedFeatureFlags.includes(ffName)) {
    LocalForage.setItem('persisted_feature_flags', [...existingPersistedFeatureFlags, ffName]);
  }
};

const getAll = async (): Promise<FeatureFlagEnum[]> => (await LocalForage.getItem('persisted_feature_flags')) || [];

const deleteFF = async (ffName: FeatureFlagEnum) => {
  const existingFeatureFlags = await getAll();

  if (existingFeatureFlags.includes(ffName)) {
    const filteredFeatureFlags = existingFeatureFlags.filter((featureFlag) => featureFlag !== ffName);

    LocalForage.setItem('persisted_feature_flags', filteredFeatureFlags);
  }
};

const clearAllFF = () => LocalForage.setItem('persisted_feature_flags', []);

export default {
  addFF,
  deleteFF,
  clearAllFF,
  getAll,
};
