import { TextField, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import Icon from 'components/Icon';
import { timezoneOptionMap, timezoneOptionsSorted, Timezone } from 'model/Timezone';

interface Props {
  onChange: (option: Timezone) => void;
  value: Timezone;
  disabled?: boolean;
  width?: number;
}

const TimezoneAutoComplete = ({ onChange, value, disabled, width = 350 }: Props) => (
  <StyledAutocomplete
    value={value}
    disabled={disabled}
    key="autocomplete"
    disableClearable
    clearOnEscape
    clearOnBlur
    disablePortal
    id="timezone-select"
    style={{ width, zIndex: 1000 }}
    options={timezoneOptionsSorted}
    autoHighlight
    getOptionLabel={(tz) => timezoneOptionMap[tz]}
    onChange={(_, option) => {
      if (option) {
        onChange(option);
      }
    }}
    isOptionEqualToValue={(option) => option === value}
    renderInput={(params) => (
      <TextField
        placeholder={value}
        {...params}
        variant="outlined"
        inputProps={{
          ...params.inputProps,
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <span
              style={{ position: 'absolute', right: 14, top: 5, transform: 'rotate(90deg)', cursor: 'pointer' }}
              onClick={params.inputProps?.onMouseDown}
            >
              <Icon icon="chevron" />
            </span>
          ),
        }}
      />
    )}
  />
);

export default TimezoneAutoComplete;

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-inputRoot {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    height: 32px;
  }
  :not(.Mui-focused) input:not([disabled]) ::placeholder {
    opacity: 1;
  }
` as typeof Autocomplete;
