import { Backdrop, Fade, ModalProps } from '@mui/material';
import React, { CSSProperties } from 'react';
import { useTheme } from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import Icon from 'components/Icon';
import { Ellipsis } from 'components/sharedStyles';
import useWindowSize from 'utils/hooks/useWindowSize';

import { ActionsButtonWrapper, Paper, ThemedModal } from './styledComponents';

type ModalOnCloseParameters = Parameters<NonNullable<ModalProps['onClose']>>;
type ModalOnCloseReturnType = ReturnType<NonNullable<ModalProps['onClose']>>;
export interface Props {
  actionButtonsDisabled?: boolean;
  children?: React.ReactNode;
  classes?: Record<'modal' | 'paper', string>;
  contentWidth?: number | string;
  hideActionButtons?: boolean;
  minWidth?: number;
  onClose?: (
    event: ModalOnCloseParameters[0],
    reason: ModalOnCloseParameters[1] | 'clickedX',
  ) => ModalOnCloseReturnType;
  onPrimaryClicked?: (...rest: any[]) => void; // eslint-disable-line
  onSecondaryClicked?: (...rest: any[]) => void; // eslint-disable-line
  open: boolean;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonDisabled?: boolean;
  style?: CSSProperties;
  title?: string | (() => React.ReactNode);
  subtitle?: React.ReactNode;
  disableClose?: boolean;
  marginTop?: number;
  contentHeight?: CSSProperties['height'];
  buttonPosition?: string;
  dataqa?: string;
}

const ModalComponent = ({
  actionButtonsDisabled,
  contentWidth = 'auto',
  hideActionButtons = false,
  minWidth,
  onClose,
  classes,
  onPrimaryClicked,
  onSecondaryClicked,
  primaryButtonLabel,
  primaryButtonDisabled,
  secondaryButtonLabel,
  secondaryButtonDisabled,
  title,
  subtitle,
  style,
  children,
  open,
  disableClose = false,
  marginTop = 32,
  contentHeight,
  buttonPosition,
  dataqa,
}: Props) => {
  const theme = useTheme();
  const { mobile, width } = useWindowSize();
  return (
    <ThemedModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes?.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      disablePortal
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
      style={{ zIndex: theme.layer.modalBackdrop, maxHeight: `${window.innerHeight}px` }}
      data-qa={dataqa}
    >
      <Fade in={open}>
        <Paper key="fade" className={classes?.paper} isMobile={mobile} width={width} minWidth={minWidth} style={style}>
          {!disableClose && (
            <div
              key="on-close"
              onClick={(event) => onClose?.(event, 'clickedX')}
              style={{
                fontSize: mobile ? 12 : 18,
                textAlign: 'right',
                cursor: 'pointer',
                position: mobile ? 'fixed' : 'absolute',
                right: 20,
                top: 20,
                zIndex: 10,
                color: '#191919',
              }}
            >
              <Icon icon="close" />
            </div>
          )}
          {typeof title === 'string' ? (
            <h2 style={{ textAlign: 'center', fontSize: 28, paddingTop: '20px' }}>
              <Ellipsis>{title}</Ellipsis>
            </h2>
          ) : (
            title?.()
          )}
          {!!subtitle && subtitle}

          <div style={{ marginTop, height: contentHeight }} key="children">
            {children}
          </div>

          {!hideActionButtons && (
            <ActionsButtonWrapper
              justify={buttonPosition}
              style={{
                width: contentWidth,
              }}
            >
              {onSecondaryClicked && secondaryButtonLabel && (
                <SecondaryButton
                  key="btn-secondary"
                  onClick={onSecondaryClicked}
                  disabled={actionButtonsDisabled || !onSecondaryClicked || secondaryButtonDisabled}
                  style={{ marginRight: 16 }}
                  data-qa="secondary-modal-button"
                >
                  {secondaryButtonLabel}
                </SecondaryButton>
              )}
              {onPrimaryClicked && primaryButtonLabel && (
                <PrimaryButton
                  key="btn-primary"
                  onClick={onPrimaryClicked}
                  disabled={actionButtonsDisabled || !onPrimaryClicked || primaryButtonDisabled}
                  data-qa="primary-modal-button"
                >
                  {primaryButtonLabel}
                </PrimaryButton>
              )}
            </ActionsButtonWrapper>
          )}
        </Paper>
      </Fade>
    </ThemedModal>
  );
};

export default ModalComponent;
