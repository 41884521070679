export enum IconSize {
  xs = 8,
  s = 12,
  m = 16,
  l = 20,
  xl = 24,
}

export enum IconRotation {
  Up = 0,
  Right = 90,
  Down = 180,
  Left = 270,
}

export type IconDeclaration = {
  [K in keyof JSX.IntrinsicElements]?: JSX.IntrinsicElements[K];
};
