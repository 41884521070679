import { gql, useQuery } from '@apollo/client';

import { Department } from 'model/misc';

export const DEPARTMENTS_GQL = gql`
  query Departments {
    departments {
      edges {
        id
        name
        school {
          id
          name
        }
        center
      }
    }
  }
`;

export const useDepartments = () => {
  const { data, ...rest } = useQuery<{ departments: { edges: Department[] } }>(DEPARTMENTS_GQL);

  return {
    departments: data?.departments?.edges,
    ...rest,
  };
};

export const useTeacherCenters = () => {
  const { departments } = useDepartments();

  return departments?.map((department) => ({ value: department.id, name: department.name })) || [];
};

export const useTeacherCenterById = (id: string | undefined) => {
  const { departments } = useDepartments();

  return departments?.find((department) => department.id === id);
};

export const useSchoolId = () => {
  const { departments } = useDepartments();
  return departments?.[0]?.school?.id; // we have the same schoolId for all departments for now. So picking the first one
};
