import styled, { css, keyframes } from 'styled-components';

import { SpacingScale } from 'theme/index';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  ${(props) => css`
    display: block;
    width: ${props.theme.spacingScale.withUnits(SpacingScale.s)};
    height: ${props.theme.spacingScale.withUnits(SpacingScale.s)};
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
    border-color: transparent transparent ${props.theme.color.border.disabled} ${props.theme.color.border.disabled};
    right: 20px;
    top: calc(
      50% - ${props.theme.spacingScale.withoutUnits(SpacingScale.xs)}px -
        ${props.theme.spacingScale.withoutUnits(SpacingScale.xxs)}px
    );
    transform-origin: 50% 50%;
    animation: ${spin} 0.4s linear infinite;
  `}
`;
