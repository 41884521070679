import styled, { DefaultTheme, css } from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import { spacing } from 'theme/spacing';

export const InfoWrapper = styled.div`
  padding: ${spacing('s')} 0;
`;

export const InfoLine = styled.div`
  font-size: ${({ theme }) => theme.fontSize[14]};
  margin-top: ${spacing('xs')};
  line-height: ${({ theme }) => theme.lineHeight[22]};
  text-transform: capitalize;
  &.first-of-type {
  }
  .icon-wrapper {
    display: inline;
    margin-right: ${spacing('xs')};
    position: relative;
    top: 2px;
    padding-right: 0;
  }
`;

export const PopupDiv = styled.div<{ responsive?: boolean }>`
  width: ${(props) => (props.responsive ? '100%' : '345px')};
  background-color: ${({ theme }) => theme.color.white};
  ${(props) => props.responsive && `height: ${window.innerHeight}px`};
`;

export const EnterClassButton = styled(PrimaryButton)<{ disabled: boolean }>`
  margin-left: ${spacing('s')};
  width: 148px;
  height: ${spacing('l')};
  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ''}
`;

type FlexDivState = 'active-pl' | 'active-gl' | 'past' | 'past-error';
const getFlexDivBGColor = (color: DefaultTheme['color'], state: FlexDivState) =>
  ({
    'active-pl': color.plBookingBg,
    'active-gl': color.pelorous,
    past: color.starDust,
    'past-error': color.warning,
  }[state]);

export const FlexDiv = styled.div<{ state: FlexDivState }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: ${({ theme }) => theme.form.input.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.form.input.borderRadius};
  padding: ${spacing('xs')};
  color: ${({ theme }) => theme.color.white};
  align-items: baseline;
  background-color: ${({ theme, state }) => getFlexDivBGColor(theme.color, state)};
`;

export const PrepButton = styled(SecondaryButton)`
  min-width: 147px;
  height: ${spacing('l')};
  border-radius: 20px;
  margin-top: ${spacing('xs')};
  width: 147px;
`;

export const MobileSuboutButton = styled(SecondaryButton)`
  width: 100%;
`;

export const SupportDiv = styled.div<{ notClickable?: boolean; color: 'dark' | 'light'; stretched?: boolean }>`
  margin-right: ${spacing('s')};
  color: ${({ theme, color }) => (color === 'dark' ? theme.color.inkBlack : theme.color.white)};
  font-size: ${({ theme }) => theme.fontSize[14]};
  ${({ stretched }) =>
    stretched &&
    css`
      flex-grow: 1;
    `}
  ${({ notClickable }) =>
    !notClickable &&
    css`
      cursor: pointer;
    `}
`;

export const BookingErrorDesc = styled(SupportDiv)`
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 44%;
  margin-left: ${spacing('xs')};
`;

export const PopupHeaderIconWrapper = styled.div`
  cursor: pointer;
  z-index: 10;
`;

export const PopupHeaderMobile = styled.div`
  min-height: ${spacing('xxl')};
  background-color: ${({ theme }) => theme.color.aquaSpring};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  position: relative;
`;

export const PopupHeaderMobileText = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  display: inline;
  margin-right: ${spacing('xs')};
  position: relative;
  top: 2px;
`;

export const SuboOutWrapper = styled.div`
  color: ${({ theme }) => theme.color.inkBlack};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize[14]};
  margin-right: ${spacing('s')};
`;

export const ContentWrapper = styled.div`
  padding: ${spacing('s')};
`;

export const BookingIdWrapper = styled.div`
  display: inline;
  font-size: ${({ theme }) => theme.fontSize[12]};
  line-height: 1.83;
  cursor: pointer;
`;

export const CopiedMessage = styled.span`
  color: ${({ theme }) => theme.color.helloPink};
  font-weight: bold;
  margin-left: ${spacing('xs')};
`;

export const Menu = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
  box-shadow: 0 2px 8px 0 ${({ theme }) => theme.color.inkBlack}28;
  position: absolute;
  top: 30px;
  left: -120px;
  width: 140px;
  height: auto;
  padding: 5px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize[14]};
  border-radius: 2px;
  height: 38px;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.color.inkBlack};
  &:hover {
    background-color: ${({ theme }) => theme.color.efGrey};
  }
`;

export const MenuWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${spacing('s')};
  right: 10px;
`;

export const PopupBackground = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 34;
`;

export const AxisPopupWrapper = styled.div`
  display: 'inline-grid';
  column-gap: 16;
  row-gap: 10;
`;

export const LessonDescription = styled.div`
  grid-column: 2;
  color: 'gray';
  font-size: ${({ theme }) => theme.fontSize[12]};
  margin-top: 13;
  line-height: 1.67;
`;

export const LessonTimeWrapper = styled.div`
  grid-column: 2;
`;

export const LessonTime = styled(InfoLine)`
  margin-bottom: ${spacing('s')};
`;
