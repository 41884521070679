import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  left: 324px;
  bottom: 156px;
`;

export const Modal = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgb(25, 25, 25);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 288px;
  overflow: hidden;
  font-weight: bold;
  color: #fff;
`;

export const Accent = styled.div`
  position: absolute;
  bottom: 16px;
  left: -28px;
  transform: rotate(-90deg);
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-bottom: 10px solid rgb(25, 25, 25);
`;

export const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding-left: 42px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 12px;
  cursor: default;
`;

export const Option = styled.div<{ active?: boolean }>`
  padding-left: 64px;
  padding-right: 16px;
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1.6px;
  font-weight: normal;
  &:hover {
    background: linear-gradient(
      56.5deg,
      #edf8fe 52.65%,
      #edf8fe 52.65%,
      #edf8fe 52.65%,
      #edf8fe 55.65%,
      #ffe8f5 95.13%
    );
    color: #191919;
  }
`;

export const Hr = styled.div`
  border-top: 1px solid rgb(48, 48, 48);
`;
