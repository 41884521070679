import React from 'react';

import CheckedIcon from 'assets/appswitcher/checked-icon.svg';
import OnClickOutside from 'components/OnClickOutside';
import { useTeacherContext } from 'context/Profile';
import { ITeacherProfile } from 'model/profiles';

import { Accent, Modal, Wrapper, Option, OptionHeader, Hr } from './styledComponents';

interface Props {
  teacherProfiles: ITeacherProfile[];
  onClose: () => void;
}

const ProfileSelect = ({ teacherProfiles, onClose }: Props) => {
  const { selectedTeacher, setTeacher } = useTeacherContext();
  const schools = teacherProfiles
    .map((teacher) => teacher.department.school.name)
    .filter((school, index, self) => self.indexOf(school) === index && school !== undefined)
    .sort((a, b) => a.localeCompare(b));

  return (
    <OnClickOutside fn={onClose}>
      <Wrapper>
        <Modal data-qa="profile-select-popup">
          <Hr />
          {schools?.map((school) => (
            <>
              <OptionHeader data-qa="profile-select-heading" key={school}>
                {school}
              </OptionHeader>
              <Hr />
              {teacherProfiles
                .filter((profile) => profile.department.school.name === school)
                .map((profile) => (
                  <Option
                    key={profile.id}
                    data-qa="profile-select-item"
                    onClick={() => {
                      setTeacher(profile.id);
                      onClose();
                    }}
                  >
                    <span data-qa={profile.department.name}>{profile.department.name}</span>
                    {selectedTeacher.id === profile.id && (
                      <CheckedIcon data-qa={`checked-${profile.department.name}`} />
                    )}
                  </Option>
                ))}
            </>
          ))}
        </Modal>
        <Accent />
      </Wrapper>
    </OnClickOutside>
  );
};

export default ProfileSelect;
