import styled from 'styled-components';

import { BOX_SHADOW } from 'components/sharedStyles';

export const PrimaryButton = styled.button`
  background-color: #017aca;
  border-radius: 24px;
  border: 0;
  box-shadow: 3px 3px 15px 0 rgba(0, 159, 238, 0.53), -6px -6px 20px 0 #fffafe;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  letter-spacing: normal;
  line-height: 1.71;
  outline: 0;
  text-align: center;
  width: 163px;
  user-select: none;
  transition: all 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${BOX_SHADOW}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
    box-shadow: none;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: #ffffff;
  border: solid 1px #017aca;
  box-shadow: none;
  color: #017aca;
`;

export const InvertedPrimaryButton = styled(PrimaryButton)`
  background-color: #017aca;
  box-shadow: 0 0 20px 0 rgba(255, 250, 254, 0.35);
`;

export const LinkButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.tfBlue};
  white-space: nowrap;

  &:disabled {
    color: ${({ theme }) => theme.color.starDust};
    cursor: initial;
  }
`;
