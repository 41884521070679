import React, { createContext, useContext, useState, SetStateAction, Dispatch, useEffect } from 'react';

import { NOOP } from 'utils/miscellaneous';

import { FeatureFlagEnum, PushStatus } from './enums';
import FFPersist from './feature-flag-persistance';

interface IFeatureFlagContext {
  featureFlags: FeatureFlagEnum[];
  setFeatureFlags: Dispatch<SetStateAction<FeatureFlagEnum[]>>;
}

export const FeatureFlagContext = createContext<IFeatureFlagContext>({
  featureFlags: [],
  setFeatureFlags: NOOP,
});

export const useFeatureFlag = (ffName: FeatureFlagEnum) => {
  const { featureFlags } = useContext(FeatureFlagContext);

  return featureFlags.includes(ffName);
};

const getReturnMessage = (activated: boolean, persisted: boolean): PushStatus => {
  if (activated && persisted) {
    return 'Activated and persisted';
  } else if (persisted) {
    return 'Persisted';
  } else if (activated) {
    return 'Activated';
  } else {
    return 'Already active';
  }
};

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagEnum[]>([]);

  useEffect(() => {
    const loadPersistedFeatureflags = async () => {
      const persistedFeatureFlags = await FFPersist.getAll();
      if (persistedFeatureFlags?.length) {
        setFeatureFlags(persistedFeatureFlags);
      }
    };

    loadPersistedFeatureflags();
  }, []);

  useEffect(() => {
    window.FF = {
      push: (ffName, persist) => {
        let activated = false;
        let persisted = false;

        setFeatureFlags((previousFeatureFlags) => {
          const newFeatureFlags = [...previousFeatureFlags];
          if (!newFeatureFlags.includes(ffName)) {
            newFeatureFlags.push(ffName);
            activated = true;
          }

          return newFeatureFlags;
        });

        if (persist) {
          FFPersist.addFF(ffName);
          persisted = true;
        }

        return getReturnMessage(activated, persisted);
      },
      pop: (ffName) => {
        if (featureFlags.includes(ffName)) {
          setFeatureFlags((previousFeatureFlags) => previousFeatureFlags.filter((ff) => ff !== ffName));
          FFPersist.deleteFF(ffName);
          return 'Deactivated';
        }
        return 'No effect. Already deactivated';
      },
      list: () => {
        FFPersist.getAll().then((persistedFeatureFlags) => {
          const nonPersistedFeatureFlags = featureFlags.filter(
            (featureFlag) => !persistedFeatureFlags.includes(featureFlag),
          );

          /* eslint-disable  no-console */
          console.group('Persisted feature flags');
          console.log(persistedFeatureFlags.sort());
          console.groupEnd();

          console.group('Temporary feature flags');
          console.log(nonPersistedFeatureFlags.sort());
          console.groupEnd();
          /* eslint-enable */
        });
      },
      clear: () => {
        setFeatureFlags([]);
        FFPersist.clearAllFF();
        return 'All feature flags cleared';
      },
    };
  }, [featureFlags]);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, setFeatureFlags }}>{children}</FeatureFlagContext.Provider>
  );
};

export default FeatureFlagContext;
