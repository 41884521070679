import React, { useContext } from 'react';

import { FeatureFlagEnum } from './enums';
import FeatureFlagContext from './Provider';

interface ComsumerProps {
  flag: FeatureFlagEnum;
}

const Consumer: React.FC<ComsumerProps> = ({ flag, children }) => {
  const { featureFlags } = useContext(FeatureFlagContext);

  if (typeof children === 'function') {
    return children(featureFlags.includes(flag));
  }

  return featureFlags.includes(flag) ? <>{children}</> : null;
};

export default Consumer;
