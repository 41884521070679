import { useTeacherContext, useUserContext } from 'context/Profile';
import { IUserRoleType, Permissions, Resources } from 'model/profiles';

const usePermissions = () => {
  const { user } = useUserContext();

  // TODO - add handling for role based permissions once backend includes the role for the permission in the response.
  // we can then filter out the permissions based on the role in use and avoid having to include both admin and teacher permissions
  // in the returned response

  const getPermission = (permission: Permissions, resourceType: string, resourceId?: string) => {
    if (user) {
      const grants = user.permissions.filter((g) => g.resourceType === resourceType && g.permission === permission);

      if (grants.length > 0) {
        for (const grant of grants) {
          if (
            (resourceType === Resources.TEACHER && grant.resourceId === undefined) ||
            (resourceType === Resources.HIERARCHY &&
              (!resourceId || grant.resourceId === '' || resourceId.startsWith(grant.resourceId)))
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const hasRole = (role: IUserRoleType | IUserRoleType[]) =>
    Array.isArray(role) ? user.roles?.some((r) => role.includes(r.role)) : user.roles?.some((r) => r.role === role);

  return {
    hasRole,

    permissions: {
      admin: {
        ViewOpsPortal: (resourceId?: string) =>
          getPermission(Permissions.VIEW_OPS_PORTAL, Resources.HIERARCHY, resourceId && resourceId),
        ViewOpsPortalForStudentsPortal: (resourceId?: string) =>
          getPermission(Permissions.VIEW_OPS_PORTAL_FOR_STUDENTS_PORTAL, Resources.HIERARCHY, resourceId && resourceId),
        ViewStudentsPortal: (resourceId?: string) =>
          getPermission(Permissions.VIEW_STUDENTS_PORTAL, Resources.HIERARCHY, resourceId && resourceId),
        ViewClassRecords: (resourceId?: string) =>
          getPermission(Permissions.VIEW_CLASS_RECORDS, Resources.HIERARCHY, resourceId && resourceId),
        EditClassRecordsCenterStatus: (resourceId?: string) =>
          getPermission(Permissions.EDIT_CLASS_RECORDS_CENTER_STATUS, Resources.HIERARCHY, resourceId && resourceId),
        EditClassRecordsSystemStatus: (resourceId?: string) =>
          getPermission(Permissions.EDIT_CLASS_RECORDS_SYSTEM_STATUS, Resources.HIERARCHY, resourceId && resourceId),
        ViewAvailability: (resourceId?: string) =>
          getPermission(Permissions.VIEW_AVAILABILITY, Resources.HIERARCHY, resourceId && resourceId),
        ViewExclusiveAvailability: (resourceId?: string) =>
          getPermission(Permissions.VIEW_EXCLUSIVE_AVAILABILITY, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeachers: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHERS, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherRole: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_ROLE, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherRole: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_ROLE, Resources.HIERARCHY, resourceId && resourceId),
        ViewInvoices: (resourceId?: string) =>
          getPermission(Permissions.VIEW_INVOICES, Resources.HIERARCHY, resourceId && resourceId),
        ReviewInvoices: (resourceId?: string) =>
          getPermission(Permissions.REVIEW_INVOICES, Resources.HIERARCHY, resourceId && resourceId),
        CreateInvoices: (resourceId?: string) =>
          getPermission(Permissions.CREATE_INVOICES, Resources.HIERARCHY, resourceId && resourceId),
        EditAvailability: (resourceId?: string) =>
          getPermission(Permissions.EDIT_AVAILABILITY, Resources.HIERARCHY, resourceId && resourceId),
        EditExclusiveAvailability: (resourceId?: string) =>
          getPermission(Permissions.EDIT_EXCLUSIVE_AVAILABILITY, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherFirstName: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_FIRST_NAME, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherFirstName: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_FIRST_NAME, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherLastName: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_LAST_NAME, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherLastName: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_LAST_NAME, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherFullName: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_FIRST_NAME, Resources.HIERARCHY, resourceId && resourceId) &&
          getPermission(Permissions.VIEW_TEACHER_LAST_NAME, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherEmail: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_EMAIL, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherEmail: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_EMAIL, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherSkills: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_SKILLS, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherSkills: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_SKILLS, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherInstructionLanguages: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_INSTRUCTION_LANGUAGES, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherInstructionLanguages: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_INSTRUCTION_LANGUAGES, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherPersonalInterests: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_PERSONAL_INTERESTS, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherPersonalInterests: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_PERSONAL_INTERESTS, Resources.HIERARCHY, resourceId && resourceId),
        CreateTeacherProfile: (resourceId?: string) =>
          getPermission(Permissions.CREATE_TEACHER_PROFILE, Resources.HIERARCHY, resourceId && resourceId),
        ViewAttendance: (resourceId?: string) =>
          getPermission(Permissions.VIEW_ATTENDANCE, Resources.HIERARCHY, resourceId && resourceId),
        ViewBookingExitSurvey: (resourceId?: string) =>
          getPermission(Permissions.VIEW_BOOKING_EXIT_SURVEY, Resources.HIERARCHY, resourceId && resourceId),
        CreateBookingsByTeacher: (resourceId?: string) =>
          getPermission(Permissions.CREATE_BOOKING_BY_TEACHER, Resources.HIERARCHY, resourceId && resourceId),
        CreateBlockList: (resourceId?: string) =>
          getPermission(Permissions.CREATE_BLOCK, Resources.HIERARCHY, resourceId && resourceId),
        ViewBlockList: (resourceId?: string) =>
          getPermission(Permissions.VIEW_BLOCK, Resources.HIERARCHY, resourceId && resourceId),
        DeleteBlockList: (resourceId?: string) =>
          getPermission(Permissions.DELETE_BLOCK, Resources.HIERARCHY, resourceId && resourceId),
        ViewClassroomRecording: (resourceId?: string) =>
          getPermission(Permissions.VIEW_CLASSROOM_RECORDING, Resources.HIERARCHY, resourceId && resourceId),
        UpdateBookingExitSurvey: (resourceId?: string) =>
          getPermission(Permissions.UPDATE_BOOKING_EXIT_SURVEY, Resources.HIERARCHY, resourceId && resourceId),
        ForceApproveInvoices: (resourceId?: string) =>
          getPermission(Permissions.FORCE_APPROVE_INVOICES, Resources.HIERARCHY, resourceId && resourceId),
        // handled for calendar active booking
        // not yet handled for edit availablity where bookings will get subbed out
        SubOutBooking: (resourceId?: string) =>
          getPermission(Permissions.SUBOUT_BOOKING, Resources.HIERARCHY, resourceId && resourceId),
        ViewUnclaimedBookings: (resourceId?: string) =>
          getPermission(Permissions.VIEW_UNCLAIMED_BOOKINGS, Resources.HIERARCHY, resourceId && resourceId),
        AnnouncementEdit: (resourceId?: string) =>
          getPermission(Permissions.ANNOUNCEMENT_EDIT, Resources.HIERARCHY, resourceId && resourceId),
        AnnouncementView: (resourceId?: string) =>
          getPermission(Permissions.ANNOUNCEMENT_VIEW, Resources.HIERARCHY, resourceId && resourceId),
        BlockedSlotView: (resourceId?: string) =>
          getPermission(Permissions.BLOCKED_SLOT_VIEW, Resources.HIERARCHY, resourceId && resourceId),
        BlockedSlotEdit: (resourceId?: string) =>
          getPermission(Permissions.BLOCKED_SLOT_EDIT, Resources.HIERARCHY, resourceId && resourceId),

        // NOT CURRENTLY USED
        ViewTeacherEmailSubscription: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_EMAIL_SUBSCRIPTION, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherEmailSubscription: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_EMAIL_SUBSCRIPTION, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherAsotMemberID: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_ASOT_MEMBER_ID, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherAsotMemberID: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_ASOT_MEMBER_ID, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherPayRate: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_PAY_RATE, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherPayRate: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_PAY_RATE, Resources.HIERARCHY, resourceId && resourceId),
        ViewTeacherPath: (resourceId?: string) =>
          getPermission(Permissions.VIEW_TEACHER_PATH, Resources.HIERARCHY, resourceId && resourceId),
        EditTeacherPath: (resourceId?: string) =>
          getPermission(Permissions.EDIT_TEACHER_PATH, Resources.HIERARCHY, resourceId && resourceId),
        CreateChatRoom: (resourceId?: string) =>
          getPermission(Permissions.CHATROOM_CREATE, Resources.HIERARCHY, resourceId && resourceId),
      },
    },
  };
};

export const useTeacherPermissions = () => {
  const { user } = useUserContext();
  const teacher = useTeacherContext().selectedTeacher;

  const getPermission = (permission: Permissions) =>
    user.permissions.findIndex(
      (grant) =>
        grant.resourceType === Resources.TEACHER && grant.resourceId === teacher.id && grant.permission === permission,
    ) >= 0;

  const hasRole = (role: IUserRoleType | IUserRoleType[]) =>
    Array.isArray(role) ? user.roles?.some((r) => role.includes(r.role)) : user.roles?.some((r) => r.role === role);

  return {
    hasRole,
    permissions: {
      teacher: {
        ViewTeachersPortal: () => getPermission(Permissions.VIEW_TEACHERS_PORTAL),
        ViewTeachers: () => getPermission(Permissions.VIEW_TEACHERS),
        ViewInvoices: () => getPermission(Permissions.VIEW_INVOICES),
        ViewClassRecords: () => getPermission(Permissions.VIEW_CLASS_RECORDS),
        SubmitInvoices: () => getPermission(Permissions.SUBMIT_INVOICES),
        CreateInvoices: () => getPermission(Permissions.CREATE_INVOICES),
        ViewAvailability: () => getPermission(Permissions.VIEW_AVAILABILITY),
        EditAvailability: () => getPermission(Permissions.EDIT_AVAILABILITY),
        ViewTeacherFirstName: () => getPermission(Permissions.VIEW_TEACHER_FIRST_NAME),
        ViewTeacherLastName: () => getPermission(Permissions.VIEW_TEACHER_LAST_NAME),
        ViewTeacherFullName: () =>
          getPermission(Permissions.VIEW_TEACHER_FIRST_NAME) && getPermission(Permissions.VIEW_TEACHER_LAST_NAME),
        ViewTeacherEmail: () => getPermission(Permissions.VIEW_TEACHER_EMAIL),
        ViewTeacherSkills: () => getPermission(Permissions.VIEW_TEACHER_SKILLS),
        ViewTeacherInstructionLanguages: () => getPermission(Permissions.VIEW_TEACHER_INSTRUCTION_LANGUAGES),
        EditTeacherInstructionLanguages: () => getPermission(Permissions.EDIT_TEACHER_INSTRUCTION_LANGUAGES),
        ViewTeacherPersonalInterests: () => getPermission(Permissions.VIEW_TEACHER_PERSONAL_INTERESTS),
        EditTeacherPersonalInterests: () => getPermission(Permissions.EDIT_TEACHER_PERSONAL_INTERESTS),
        ViewBookingExitSurvey: () => getPermission(Permissions.VIEW_BOOKING_EXIT_SURVEY),
        ViewPeakRanges: () => getPermission(Permissions.VIEW_PEAK_RANGES),
        CreateBookingsByTeacher: () => getPermission(Permissions.CREATE_BOOKING_BY_TEACHER),
        ViewExclusiveAvailability: () => getPermission(Permissions.VIEW_EXCLUSIVE_AVAILABILITY),
        EditExclusiveAvailability: () => getPermission(Permissions.EDIT_EXCLUSIVE_AVAILABILITY),
        EditReserveTimeslotSettings: () => getPermission(Permissions.EDIT_RESERVE_TIMESLOT_SETTINGS),
        // handled for calendar active booking
        // not yet handled for edit availablity where bookings will get subbed out
        SubOutBooking: () => getPermission(Permissions.SUBOUT_BOOKING),
        ViewUnclaimedBookings: () => getPermission(Permissions.VIEW_UNCLAIMED_BOOKINGS),
        CreateChatRoom: () => getPermission(Permissions.CHATROOM_CREATE),

        // NOT CURRENTLY USED
        EditTeacherFirstName: () => getPermission(Permissions.EDIT_TEACHER_FIRST_NAME),
        EditTeacherLastName: () => getPermission(Permissions.EDIT_TEACHER_LAST_NAME),
        ViewTeacherPath: () => getPermission(Permissions.VIEW_TEACHER_PATH),
        ViewTeacherPayRate: () => getPermission(Permissions.VIEW_TEACHER_PAY_RATE),
        ViewTeacherEmailSubscription: () => getPermission(Permissions.VIEW_TEACHER_EMAIL_SUBSCRIPTION),
        EditTeacherEmailSubscription: () => getPermission(Permissions.EDIT_TEACHER_EMAIL_SUBSCRIPTION),
        ClaimUnclaimedBookings: () => getPermission(Permissions.CLAIM_UNCLAIMED_BOOKINGS),
      },
    },
  };
};

export default usePermissions;
