import styled, { css } from 'styled-components';

export const EditProfileLink = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: #017aca;
  font-weight: normal;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 16px;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #191919;
  margin-top: 37px;
  margin-bottom: 11px;
  width: 250px;
`;

export const SubNavigation = styled.div`
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #efefef;
  padding-top: 20px;
`;

export const SubNavButton = styled.div<{ isActive?: boolean }>`
  width: 220px;
  height: 52px;
  font-size: 14px;
  padding-left: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${(props) =>
    props.isActive &&
    css`
      border-right: 2px solid ${({ theme }) => theme.color.tfBlue};
      background-color: ${({ theme }) => theme.color.zircon};
      font-weight: 400;
      margin-right: -1.5px;
    `}

  &:hover {
    color: #017aca;
  }
`;

export const SubNavText = styled.div`
  margin: 2px 8px 0;
  font-weight: 400;
  .icon {
    padding-right: 8px;
    svg {
      transform: translateY(4px);
    }
  }
`;

export const ExpandedContainer = styled.div`
  width: 590px;
  height: 100%;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: -10px;
  cursor: pointer;
`;

export const SettingsNotificationsStyles = styled.div`
  margin: 20px;
  h2 {
    font-size: 20px;
  }
`;

export const SwitchStyles = styled.p`
  display: flex;
  p {
    font-size: 14px;
    line-height: 20px;
    padding-top: 2px;
    margin-left: 14px;
  }
  padding-top: 15px;
`;

export const SettingsStyles = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  h1 {
    margin-left: 20px;
    margin-top: 26px;
    font-size: 20px;
  }
`;

export const SettingsWrapper = styled.section`
  display: flex;
  flex-grow: 1;
`;

export const CheckBoxStyle = styled.div`
  padding-bottom: 15px;

  .MuiIconButton-root {
    padding-top: 9px;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #017aca;
    padding-top: 9px;
  }
  .MuiFormControlLabel-label {
    padding-top: 2px;
    font-size: 14px;
  }
`;

export const CalendarUrl = styled.div`
  width: 400px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 9px;
  font-size: 14px;
  color: #191919;
`;
