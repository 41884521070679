import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  Aligned,
  Container,
  ContentWhiteCard,
  Footer,
  Halo,
  LoginButton,
  LogoContainer,
  Page,
  RequestId,
  ErrorImage,
  SummaryBody,
  Title4,
} from './StyledComponents';

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props extends RouteComponentProps<{}> {
  isOpsApp?: boolean;
}

const fullYear = new Date().getFullYear();

const UnauthorizedUserScreen = (props: Props) => {
  const { instance: msalInstance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const searchParams = new URLSearchParams(props.location.search);
  const azureId = searchParams.get('azureId');
  const theme = useTheme();

  return (
    <Page background={props.isOpsApp ? theme.color.serenade : theme.color.zircon}>
      <Halo src={props.isOpsApp ? '/assets/ops-halo-group.svg' : '/assets/teacher-halo-group.svg'} />
      <Container>
        <LogoContainer>
          <img src="/assets/TF_Logo_Black.svg" />
        </LogoContainer>
        <ContentWhiteCard>
          <Aligned>
            <Title4>Woops!</Title4>
            <SummaryBody>Sorry, you don’t have permission to view this page.</SummaryBody>
            {azureId && <RequestId>Request ID: {azureId}</RequestId>}
            <ErrorImage src={props.isOpsApp ? 'assets/alert-ops-offline.svg' : 'assets/alert-offline.svg'} />
            {isAuthenticated ? (
              <LoginButton onClick={() => msalInstance.logoutRedirect()}>Use another account</LoginButton>
            ) : (
              <LoginButton onClick={() => msalInstance.loginRedirect()}>Back to login</LoginButton>
            )}
          </Aligned>
        </ContentWhiteCard>
        <Footer>© {fullYear} EF Education First</Footer>
      </Container>
    </Page>
  );
};

export default UnauthorizedUserScreen;
