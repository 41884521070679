import Cookies from 'js-cookie';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Loading } from 'components/Loading';
import { UNAUTHORIZED_USER_PATH } from 'config/internalUrlPaths';
import useUserContext from 'context/Profile/UserProfile';
import { ITeacher, Permissions, Resources } from 'model/profiles';
import { useTeacherProfile } from 'network/Profile';

export interface ITeacherContext {
  selectedTeacher: ITeacher;
  availableTeacherIDs: string[];
  setTeacher: (teacherID: string) => void;
}

export const TeacherContext = createContext<ITeacherContext>({} as ITeacherContext);

interface Props {
  children: ReactNode;
}

export const ProvideTeacher = (props: Props) => {
  const { user } = useUserContext();
  const teacherIDs = user.permissions
    .filter(
      (permission) =>
        permission.resourceType === Resources.TEACHER && permission.permission === Permissions.VIEW_TEACHERS_PORTAL,
    )
    .map((permission) => permission.resourceId);

  const [selectedTeacher, setSelectedTeacher] = useState<string | undefined>(
    Cookies.get('teacher') ?? teacherIDs.find((_) => true),
  );

  useEffect(() => {
    const activeTeacher = Cookies.get('teacher');
    if (activeTeacher && !teacherIDs.includes(activeTeacher)) {
      Cookies.remove('teacher');
      setSelectedTeacher(teacherIDs.find((_) => true));
    }
  }, [teacherIDs]);

  useEffect(() => {
    if (selectedTeacher) {
      Cookies.set('teacher', selectedTeacher);
    }
  }, [selectedTeacher]);

  const { teacher: teacherProfile } = useTeacherProfile(selectedTeacher || '');
  if (!selectedTeacher) {
    return <Redirect to={UNAUTHORIZED_USER_PATH} />;
  }

  if (!teacherProfile) {
    return <Loading />;
  }

  return (
    <TeacherContext.Provider
      value={{
        selectedTeacher: teacherProfile,
        availableTeacherIDs: teacherIDs,
        setTeacher: setSelectedTeacher,
      }}
    >
      {props.children}
    </TeacherContext.Provider>
  );
};

const useTeacherContext = () => useContext(TeacherContext);

export default useTeacherContext;
