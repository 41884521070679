import React, { useState } from 'react';

import CloseIcon from 'assets/close-drawer.svg';
import { /* INotificationSettings, */ ICalendarSettings } from 'model/Settings';
import { useTeacherSettings } from 'network/Settings';

import { SettingsCalendar } from './SettingsCalendar';
// import SettingsNotifications from './SettingsNotifications';
import {
  SubNavButton,
  SubNavigation,
  SubNavText,
  ExpandedContainer,
  CloseWrapper,
  SettingsStyles,
  SettingsWrapper,
} from './styledComponents';

enum NavItems {
  CALENDAR = 'CALENDAR',
  /* https://efcloud.atlassian.net/browse/TFA-1451 */
  // NOTIFICATIONS = 'NOTIFICATIONS',
}

interface Props {
  teacherId: string;
  close: () => void;
}

const Settings = ({ teacherId, close }: Props) => {
  const [apiTeacherSettings, storeTeacherSettings] = useTeacherSettings(teacherId);
  const [activeSubNav, setActiveSubNav] = useState<keyof typeof NavItems>(NavItems.CALENDAR);

  const { calendar /* notifications */ } = apiTeacherSettings;

  const NavigationElements = {
    [NavItems.CALENDAR]: {
      label: 'Calendar',
      Render: () => (
        <SettingsCalendar
          storeSettings={(settings: ICalendarSettings) =>
            storeTeacherSettings(teacherId, { ...apiTeacherSettings, calendar: settings })
          }
          settings={calendar}
        />
      ),
    },

    // [NavItems.NOTIFICATIONS]: {
    //   label: 'Notifications',
    //   icon: 'notificationBell' as IconUnion,
    //   Render: () => (
    //     <SettingsNotifications
    //       storeSettings={(settings: INotificationSettings) =>
    //         storeTeacherSettings(teacherId, { ...apiTeacherSettings, notifications: settings })
    //       }
    //       settings={notifications}
    //     />
    //   ),
    // },
  };
  return (
    <SettingsStyles>
      <SettingsWrapper>
        <SubNavigation>
          {(Object.keys(NavItems) as Array<keyof typeof NavItems>).map((key) => (
            <SubNavButton role="button" key={key} isActive={activeSubNav === key} onClick={() => setActiveSubNav(key)}>
              <SubNavText>{NavigationElements[key].label}</SubNavText>
            </SubNavButton>
          ))}
        </SubNavigation>
        <ExpandedContainer>
          <CloseWrapper onClick={close} data-qa="close-sidebar">
            <CloseIcon />
          </CloseWrapper>
          {(Object.keys(NavItems) as Array<keyof typeof NavItems>).map((key) => (
            <div key={key}>{key === activeSubNav && NavigationElements[key].Render()}</div>
          ))}
        </ExpandedContainer>
      </SettingsWrapper>
    </SettingsStyles>
  );
};

export default Settings;
