import React from 'react';

import { AvatarWrapper } from './styledComponents';

interface Props {
  initials: string;
  size: number;
  fontSize: number;
  fontColor?: string;
  avatar?: string;
  paddingTop?: number;
}

const Avatar = ({ initials, size, fontSize, fontColor, avatar, paddingTop }: Props) => (
  <AvatarWrapper size={size} fontSize={fontSize} fontColor={fontColor} avatar={avatar} paddingTop={paddingTop}>
    {initials}
  </AvatarWrapper>
);

export default Avatar;
