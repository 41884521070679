import useSWR from 'swr';

import env from 'config/env';
import { IEVCJoinDetails } from 'model/Classroom';
import { EVCLocation } from 'model/misc';
import { EvcProxyTunnel } from 'model/profiles';
import { ddlog } from 'utils/miscellaneous';

import makeRequest from './helpers/makeRequest';

const getEVCJoinClassroomKey = (participantId: string, bookingId: string) =>
  `bookings/${bookingId}/classrooms/evc/participants/${participantId}/token`;

export const useEVCJoinClassroom = (participantId: string, bookingId: string) =>
  useSWR<string>(getEVCJoinClassroomKey(participantId, bookingId), () =>
    fetchEVCJoinClassroomDetails(participantId, bookingId),
  );

const getEvcTunnelForTeacher = (teacherId: string) =>
  makeRequest<{ evcChinaTunnel: 'DIRECT'; teacherId: string }>({
    pathname: `evc/teacher-settings/${teacherId}`,
  }).then((response) =>
    makeRequest<EvcProxyTunnel>({
      pathname: `evc/tunnels/${response.evcChinaTunnel}`,
    }),
  );

export const useEvcTunnelForTeacher = (teacherId: string) =>
  useSWR(`evc/teacher-settings/${teacherId}`, () => getEvcTunnelForTeacher(teacherId));

export const fetchEVCJoinClassroomDetails = async (participantId: string, bookingId: string): Promise<string> => {
  const evcDetails = await makeRequest<IEVCJoinDetails>({
    pathname: `bookings/${bookingId}/classrooms/evc/participants/${participantId}/token`,
    method: 'POST',
  });
  let domain = env.EVC_TUNNELS.US_NV;

  // If evc Location is North Virginia, default to the hardcoded domain from config
  // otherwise get the evc proxy tunnel configured for particular teacher and use that as domain
  if (evcDetails.location === EVCLocation.SH) {
    const evcTunnelForTeacher = await getEvcTunnelForTeacher(participantId).catch((error) => {
      ddlog.error('Failed to get evc tunnel details', error);
    });

    if (!!evcTunnelForTeacher?.domain) {
      domain = `https://${evcTunnelForTeacher.domain}`;
    } else {
      domain = env.EVC_TUNNELS.CN_SH;
    }
  }
  const joinPath = `${domain}/evc15/meeting/home?token=${evcDetails.attendanceToken}&accesskey=${evcDetails.accessKey}`;
  return joinPath;
};
