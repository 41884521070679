import styled from 'styled-components';

export const AvatarWrapper = styled.div<{
  size: number;
  fontSize: number;
  fontColor?: string;
  avatar?: string;
  paddingTop?: number;
}>`
  position: relative;
  background-image: url('/assets/avatar/${(props) => (props.avatar ? `${props.avatar}_avatar.svg` : 'tf_avatar.svg')}');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  padding-top: ${({ paddingTop = 6 }) => paddingTop}px;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontSize}px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  font-weight: bold;
  color: ${(props) => props.fontColor || props.theme.color.educationBlue};
`;
