import React, { SVGProps } from 'react';
import styled, { css } from 'styled-components';

import { SpacingScale } from 'theme/index'; // eslint-disable-line

import icons, { IconUnion } from './IconDeclarations';
import { IconRotation, IconSize } from './shared';

export interface IconProps {
  icon: IconUnion;
  size?: IconSize | number;
  rotation?: IconRotation;
  viewBox?: string;
  color?: string;
}

interface UnstyledIconProps extends IconProps {
  className?: string;
}

const UnstyledIcon = (props: UnstyledIconProps) => {
  if (!icons[props.icon] || !icons[props.icon].paths) {
    return null;
  }
  return (
    <span data-qa={`${props.icon}-icon`} className={props.className}>
      <Svg
        width={props.size || IconSize.m}
        height={props.size || IconSize.m}
        viewBox={props.viewBox || icons[props.icon].viewBox}
        rotation={props.rotation}
      >
        {icons[props.icon].paths.map((path, i) =>
          Object.entries(path).map(([pathComponent, pathProps]) =>
            React.createElement(pathComponent, {
              key: i,
              ...pathProps,
              fill: (pathProps as SVGProps<SVGElement>).fill || 'currentColor',
            }),
          ),
        )}
      </Svg>
    </span>
  );
};

const Icon = styled(UnstyledIcon)<Partial<Pick<IconProps, 'color' | 'size'>>>(
  ({ color, icon, size }) => css`
    color: ${color || (icons[icon] && icons[icon].defaultAccent) || 'inherit'};
    height: ${size ? size.toString() + 'px' : IconSize.m.toString() + 'px'};
    width: ${size ? size.toString() + 'px' : IconSize.m.toString() + 'px'};
    ${color &&
    css`
      path {
        fill: ${color};
      }
    `}
  `,
);

const Svg = styled.svg<{ rotation?: IconRotation }>`
  transform-origin: center;
  transform: rotate(${(props) => (props.rotation ? props.rotation : '0')}deg);
`;

export const SuffixIcon = styled(Icon)<{ center?: boolean; size?: number }>(
  ({ center, size, theme }) => css`
    position: absolute;
    right: ${theme.spacingScale.withUnits(SpacingScale.s)};
    /* Nihir: Can either add an iconTop key to spacingScale or do this */
    top: ${center
      ? '50%'
      : theme.spacingScale.withoutUnits(SpacingScale.s) + theme.spacingScale.withoutUnits(SpacingScale.xxs) + 'px'};
    transform: translateY(-${size ? Math.floor(size / 2) : SpacingScale.xxs}px);
    pointer-events: none;
  `,
);

export default Icon;
